<template>
  <div class="pb-5">
    <h5 class="primary--text fw-700 font-14px text-center mb-6">
      ランチ売上の入力について
    </h5>
    <div class="font-12px text-333 line-height-15">
      <p>【ランチ、ディナーそれぞれ日報を作成したい場合】</p>
      <p>
        ランチ売上を先に報告するか、ランチ売上と当日売上を同時に報告してください。
      </p>
      <p>
        ランチのみ先に報告する場合は、当日売上タブを開かず、ランチ売上のみ入力し報告してください。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LunchSalesModal",
};
</script>
